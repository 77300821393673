<template>
<table class="table table-bordered mt-4" v-if="Olts">
    <thead>
        <tr>
        <th>Id</th>
        <th>Nazwa</th>
        <th>Typ</th>
        <th>Razem</th>
        <th></th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="olt in Olts" v-bind:key="olt.id">
            <td>{{ olt.Id }}</td>
            <td><router-link :to="`/huawei/olt/${olt.Id}`">{{olt.Name}}</router-link></td>
            <td>{{ olt.Type }}</td>
            <td>{{ olt.TotalOnts }} [{{ olt.TotalOntsOnline }}/{{ olt.TotalOntsWarning }}/{{ olt.TotalOntsOffline }}]</td>
            <td>
                <a href="#" @click="reloadOltLineAndSrvProfiles(`${olt.Id }`)"><i class="fas fa-redo-alt"></i></a>
                <router-link :to="`/huawei/olt/${olt.Id}/template`" class="ms-2"><i class="far fa-edit"></i></router-link>
            </td>
        </tr>
    </tbody>
</table>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            Olts: null,
        }
    },
    mounted() {
        const data = {
            SessionId: localStorage.getItem('user'),
        };
        axios.post("/huawei/olt/list", data).then((result) => {
            if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
            {
                this.$store.dispatch('alert/error', result.data.Error, { root: true })
                this.$store.dispatch('auth/logout')
            }

            this.Olts = result.data.OltList
        })
    },
    methods: {
        reloadOltLineAndSrvProfiles(id) {
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiOltGetOntSrvLineProfiles: {
                    OltId: parseInt(id)
                }
            };
            axios.post("/huawei/olt/getlinesrvprofile", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }
            })
        }
    }
};
</script>